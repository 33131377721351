import React from "react";
import { Link } from "gatsby";
import isBrowser from "../Auth/isBrowser";
import Layout from "../components/Layout";
import { useAuth0 } from "../hooks/useAuth0";
import routes from "../Routes";

const Index = () => {
  if (!isBrowser) return null;
  const { isAuthenticated, loginWithPopup } = useAuth0();

  return (
    <Layout>
      <div
        style={{ top: "40px" }}
        className="absolute left-0 right-0 bottom-0 bg-white z-0 m-0 bg-gray-900 h-screen"
      />
      <div className="flex content-center z-10 relative bg-gray-900 p-6 container m-auto">
        <div className="p-6 text-center m-auto">
          <h1 className="mt-20 text-5xl text-white">Welcome to EAP Brain</h1>
          <p className="text-gray-500 mb-6 mt-2 text-xl">
            Helping us deliver an amazing support experience.
          </p>
          {isAuthenticated ? (
            <Link to={routes.APP}>
              <button type="button" className="button">
                To Dashboard
              </button>
            </Link>
          ) : (
            <button
              onClick={() => loginWithPopup()}
              type="button"
              className="button"
            >
              Sign In
            </button>
          )}
        </div>
      </div>
      <img
        className="opacity-25 m-auto absolute right-0"
        alt="adsf"
        src="/brain-white.svg"
        width="500px"
      />
    </Layout>
  );
};
export default Index;
